// axios
import axios from 'axios';
import Vue from 'vue';
import Constants from '@constants';
import store from './store/store';
import router from './routes/index';
import i18n from './i18n';

const baseURL = process.env.VUE_APP_API_BASE_URL


const http = axios.create({
  baseURL,
  // define default headers here
  headers: {
    'Accept': 'application/json',
    'X-Bromide-Locale': process.env.VUE_APP_LOCALE || 'ja',
    'X-Cache': 0
  }
});
var isShowLoading = false;
let showLoadingInterval = true
// request interceptor
http.interceptors.request.use(
  config => {
    if (config.hideLayout) {
      store.commit('HIDE_LAYOUT');
    }
    // headers
    const token = Vue.$cookies.get(Constants.ACCESS_TOKEN);
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`
    }

    //set loading
    isShowLoading = config.is_show_loading;
    showLoadingInterval = config.showLoadingInterval
    if (isShowLoading) {
      store.dispatch('toggleLoading', { state: isShowLoading, interval: showLoadingInterval });
      delete config.is_loading;
    }

    return config;
  },
  e => {
    if (isShowLoading) {
      store.dispatch('toggleLoading', { state: false, interval: showLoadingInterval });
    }
    return Promise.reject(e);
  }
);

// response interceptor
http.interceptors.response.use(
  res => {
    if (isShowLoading) {
      store.dispatch('toggleLoading', { state: false, interval: showLoadingInterval });
    }

    if (res.status === 200 || res.status === 201) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res);
    }
  },
  e => {
    const ignoreErorrStatus = e.config.is_ignore_interceptors_response_error;
    if (isShowLoading) {
      store.dispatch('toggleLoading', { state: false, interval: showLoadingInterval });
    }
    if (e.response) {
      if (e.response.status === 599 && e.response.data && e.response.data.is_base_setting_maintenance) {
        store.state.has_maintenance = true;
        return Promise.reject(e.response);
      }

      if (e.response.status === 503 || e.response.status == 500) {
        store.state.error_message = e.response.data.meta.message;
        store.state.has_error = true;
        return Promise.reject(e.response);
      }

      if (e.response.status === 404 && !ignoreErorrStatus) {
        router.push({path: '/404'});
        return Promise.reject(e.response);
      }

      if (e.response.status) {
        return Promise.reject(e.response);
      }
    } else {
      store.state.error_message = i18n.t('general.api_server_busy');
      store.state.has_error = true;
      return Promise.reject(i18n.t('general.api_server_busy'));
    }
  }
);

export default http;
